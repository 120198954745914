import { INTEGRATION_DAM_GOOGLE_DRIVE } from '@/storychief/constants/Constants';

const SCOPES = 'https://www.googleapis.com/auth/drive.file';
const CLIENT_ID = window.StoryChief.services.google.key;
const API_KEY = window.StoryChief.services.googledrive.key;
const APP_ID = window.StoryChief.services.google.project;

let tokenClient;
let accessToken = null;
let isPickerInitialized = false;
let isGisInitialized = false;

export default async function loadGoogleDriveMedia({
  allowedFileTypes,
  onFileLoaded,
  onFileLoadError,
  onFileLoading,
  multiSelect,
}) {
  function initializePicker() {
    if (isPickerInitialized) return undefined;

    return new Promise((resolve) => {
      window.gapi.load('client:picker', async () => {
        await window.gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
        isPickerInitialized = true;
        resolve();
      });
    });
  }

  function initializeGis() {
    if (isGisInitialized) return;

    tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: async (response) => {
        if (response.error !== undefined) {
          throw response;
        }
        accessToken = response.access_token;
        await createPicker();
      },
    });
    isGisInitialized = true;
  }

  function startAuth() {
    if (accessToken === null) {
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      createPicker();
    }
  }

  function pickerCallback(picker) {
    if (picker.action === window.google.picker.Action.PICKED) {
      onFileLoading();
      try {
        const images = picker[window.google.picker.Response.DOCUMENTS];
        Promise.all(
          images.map(
            (_image) =>
              new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                const url = `https://www.googleapis.com/drive/v3/files/${_image.id}?alt=media`;
                xhr.open('GET', url);
                xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
                xhr.responseType = 'blob';
                xhr.addEventListener('error', reject);
                xhr.addEventListener('load', (event) => {
                  const blob = event.currentTarget.response;
                  resolve({ blob, name: _image.name });
                });

                xhr.send();
              }),
          ),
        )
          .then((objects) => {
            onFileLoaded(
              objects.map((_object) => {
                const _file = new File([_object.blob], _object.name, {
                  type: _object.blob.type,
                  lastModifiedDate: new Date(),
                });
                _file.source = INTEGRATION_DAM_GOOGLE_DRIVE;
                return _file;
              }),
            );
          })
          .catch((e) => {
            onFileLoadError(e);
          });
      } catch (e) {
        onFileLoadError(e);
      }
    }
  }

  function createPicker() {
    const view = new window.google.picker.View(window.google.picker.ViewId.FOLDERS);
    view.setMimeTypes(allowedFileTypes.map((_filetype) => _filetype.mime).join(','));
    const picker = new window.google.picker.PickerBuilder()
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .enableFeature(multiSelect ? window.google.picker.Feature.MULTISELECT_ENABLED : '')
      .enableFeature(window.google.picker.Feature.SUPPORT_DRIVES)
      .setOrigin(`${window.location.protocol}//${window.location.host}`)
      .setAppId(APP_ID)
      .setOAuthToken(accessToken)
      .addView(view)
      .setDeveloperKey(API_KEY)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
    // Fix: put picker above modals (for instance the modal-edit-block)
    const elements = document.getElementsByClassName('picker-dialog');
    elements.forEach((_element) => {
      // eslint-disable-next-line no-param-reassign
      _element.style.zIndex = '2000';
    });
  }

  initializeGis();
  await initializePicker();
  startAuth();
}
